<template>
  <div class="sidebar-search">
    <v-row class="row-item pb-0">
      <div
        v-if="searchType === 'address'"
        class="v-input searchbox theme--dark v-text-field v-text-field--is-booted v-text-field--placeholder"
        style="padding-top: 0px !important; margin-top: 0px !important"
      >
        <div class="v-input__control">
          <div class="v-input__slot">
            <div class="v-input__prepend-inner">
              <i
                aria-hidden="true"
                class="v-icon notranslate logo v-icon--dense mdi mdi-magnify theme--dark"
              ></i>
            </div>
            <div class="v-text-field__slot">
              <vue-google-autocomplete
                v-if="google"
                v-model="address"
                id="address-input"
                ref="autocomplete"
                placeholder="Search"
                @focus="searchFocused"
                @placechanged="$emit('updatePinPoint', $event)"
                @inputChange="inputChange"
                @keypress.enter="$emit('filterLocationsList')"
              />
              <v-icon
                v-if="!!search"
                class="logo mr-0 mt-1"
                dense
                @click="clearAddressInput"
              >
                mdi-close
              </v-icon>
            </div>
          </div>
        </div>
      </div>
      <v-text-field
        v-else
        v-model="search"
        class="searchbox"
        placeholder="Search"
        name="query"
        style="padding-top: 0px !important; margin-top: 0px !important"
        dark
        @focus="$emit('enableSearchActive')"
        @keydown.enter="$emit('filterLocationsList')"
      >
        <template v-slot:prepend-inner>
          <v-icon class="logo" dense>mdi-magnify</v-icon>
        </template>
        <template v-slot:append>
          <v-icon v-if="!!search" class="logo mr-0" dense @click="search = ''"
            >mdi-close</v-icon
          >
        </template>
      </v-text-field>
    </v-row>
    <v-expand-transition>
      <v-row
        class="row-item flex-nowrap align-start selectbox pb-0"
        v-show="searchActive"
      >
        <v-select
          class="searchbox-select mt-0 pt-0 pl-4"
          v-model="searchType"
          :items="searchOptions"
          :menu-props="{
            contentClass: 'search-types-list',
            bottom: true,
            offsetY: true
          }"
          item-text="title"
          item-value="value"
          append-icon="mdi-chevron-down"
        />
        <v-btn
          class="searchbox-btn text-capitalize"
          color="primary"
          @click="$emit('filterLocationsList')"
        >
          Search
        </v-btn>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'SidebarSearch',
  components: { VueGoogleAutocomplete },
  props: {
    locations: {
      type: Array,
      required: false
    },
    searchActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      isHoveringArrow: false,
      searchType: 'name',
      searchOptions: [
        {
          title: 'location name',
          value: 'name'
        },
        {
          title: 'address',
          value: 'address'
        },
        {
          title: 'location id',
          value: 'id'
        },
        {
          title: 'licence plate',
          value: 'license_plate'
        }
      ],
      address: null
    }
  },
  created() {
    this.$emit('searchTypeUpdated', this.searchType)
  },
  watch: {
    search(value) {
      this.$emit('searchUpdated', value)
      if (!value) {
        this.$emit('filterLocationsList')
      }
    },
    searchType(type) {
      this.search = ''
      this.$emit('searchTypeUpdated', type)
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    inputChange({ newVal }) {
      this.search = newVal
      if (!newVal) {
        this.search = ''
        this.$emit('updatePinPoint', null)
      }
    },
    clearAddressInput() {
      this.$refs.autocomplete.clear()
      this.search = ''
      this.$emit('updatePinPoint', null)
    },
    searchFocused() {
      this.$emit('enableSearchActive')
      const locationsList = document.querySelectorAll('.pac-container-search')
      if (!locationsList.length) {
        const allVueGoogleAutocompleteLists =
          document.querySelectorAll('.pac-container')
        allVueGoogleAutocompleteLists[
          allVueGoogleAutocompleteLists.length - 1
        ].classList.add('pac-container-searchPanel')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/sidebar_page';

.sidebar-search {
  width: 100%;
  height: 70px;
  .searchbox {
    ::v-deep .v-input__slot {
      margin-bottom: 0;
    }
    ::v-deep .v-text-field__details {
      display: none;
    }
  }
  .logo {
    color: rgba(225, 225, 225, 0.7) !important;
  }
  .selectbox {
    padding-top: 1px;
    ::v-deep .v-input__slot {
      margin-bottom: 0;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    .searchbox-select {
      ::v-deep .v-select__slot {
        background-color: #292929;
        padding-left: 30px;
        border-bottom-left-radius: 5px;
        font-size: 12px;
        .v-select__selections {
          color: #e1e1e1;
          opacity: 0.7;
        }
      }
      ::v-deep .v-icon.mdi-chevron-down {
        color: #e1e1e1 !important;
        caret-color: #e1e1e1 !important;
        opacity: 0.7;
      }
      ::v-deep .v-input__icon--append {
        padding-right: 8px;
      }
    }
    .searchbox-btn {
      max-height: 32px;
      min-width: 48px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      max-width: 50px;
      font-size: 10px !important;
      color: #ffffff !important;
      letter-spacing: normal;
    }
  }
}
</style>
<style lang="scss">
.search-types-list {
  .v-sheet.v-list {
    background-color: #292929;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 5px 0;
    .v-list-item {
      min-height: 16px;
      &__title {
        color: #e1e1e1;
        font-size: 12px;
      }
      &__content {
        padding: 5px 0 5px 15px;
      }
      &--active {
        color: transparent !important;
        caret-color: transparent !important;
        .v-list-item__title {
          color: #ffffff;
          font-weight: 500;
        }
      }
    }
  }
}
.pac-container-searchPanel {
  background-color: #292929;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 5px 0;
  width: 200px !important;
  left: 24px !important;

  .pac-item-query {
    color: #ffffff;
  }

  .pac-item {
    padding-right: 10px;
    color: rgba(255, 255, 255, 0.4);
    border-top: none;
    font-size: 12px;
    line-height: 25px;

    &:hover {
      background-color: #292929;
      cursor: pointer;
    }
  }

  .pac-icon {
    margin-right: 14px;
    background-image: none !important;
  }
}

.pac-logo:after {
  display: none !important;
}
</style>
