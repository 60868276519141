import axios from 'axios'
import auth from '@/auth'
import helpers from '@/helpers'

const anonymousEndpoints = ['setup', 'login']

const getApiSettings = (args) => {
  let baseUrl = process.env.VUE_APP_API_BASE_URL
  switch (args.service) {
    case 'users':
      baseUrl = process.env.VUE_APP_API_USER_SERVICE
      break
    case 'billing':
      baseUrl = process.env.VUE_APP_API_BILLING_SERVICE
      break
    case 'product':
      baseUrl = process.env.VUE_APP_API_PRODUCT_SERVICE
      break
    case 'merchant':
      baseUrl = process.env.VUE_APP_API_MERCHANT_SERVICE
      break
    default:
      // @todo - revisit it after fully migrated to microservices
      break
  }

  if (args.isAdmin) {
    baseUrl += 'admin/'
  }

  const settings = {
    method: args.method,
    url: baseUrl + args.url,
    headers: {
      'Content-Type': 'application/json'
      // @todo - re-enable language when refactored backend supports
      // lang: i18n.locale || 'en'
    }
  }

  if (args.responseType) {
    settings.responseType = args.responseType
  }

  if (anonymousEndpoints.indexOf(args.url) === -1) {
    settings.headers.Authorization = `Bearer ${helpers.fetchStorage(
      'pb-token'
    )}`
  }

  switch (args.method) {
    case 'get':
      if (args.params) {
        settings.params = args.params
      }
      break
    default:
      if (args.data) {
        settings.data = args.data
      }
      break
  }

  return settings
}

/**
 * Handle success response in general
 */
const handleSuccess = (response) => {
  // update the refeshed token to localStorage
  const refreshedToken = response.headers['refreshed-token']
  if (refreshedToken) {
    helpers.saveStorage('pb-token', refreshedToken)
  }
}

/**
 * Handle error response in general
 */
const handleError = async (error, apiArgs) => {
  // log user out on token expired (status code 401)
  if (
    error.response &&
    error.response.status === 401 &&
    anonymousEndpoints.indexOf(apiArgs.url) === -1
  ) {
    if (apiArgs._retry) {
      auth.logout()
      return
    }
    await retryRequest(apiArgs)
  }
}

/**
 * Call backend API, with predefined settings
 * - Base API URL
 * - Injected token in header
 * - Refreshed token after succesful call
 * - Logout on token expired
 *
 * @param {Object} args contains basic attributes of an API call. * is mandatory
 * - method*: http method e.g. get, post, put, delete
 * - url*: the endpoint, not including base api url
 * - headers: additional header if needed
 * - params: parameters sending along with 'get' or 'delete' request
 * - data: data sending along with 'post' or 'put' request
 * @param {Function} successCallback callback on api call successful
 * @param {Function} failedCallback callback on api call failed
 */
const call = (args, successCallback, failedCallback) => {
  var settings = getApiSettings(args)
  axios(settings)
    .then((response) => {
      handleSuccess(response)

      if (typeof successCallback === 'function') {
        successCallback(response.data)
      }
    })
    .catch((error) => {
      handleError(error, args)

      failedCallback(error.response ? error.response.data : error)
    })
}

const retryRequest = async (args) => {
  args._retry = true
  await auth.refreshToken()
  await promise(args)
}

const promise = async (args) => {
  handleMaintenance()
  const settings = getApiSettings(args)
  try {
    const callResult = await axios(
      Object.assign(settings, { disableLoader: !!args.disableLoader })
    )
    handleSuccess(callResult)
    return callResult.data
  } catch (error) {
    await handleError(error, args)
    if (error.response.data.error) {
      throw error.response.data.error
    } else {
      throw error.response.data.errors
    }
  }
}

const handleMaintenance = () => {
  if (!helpers.isUnderMaintenance()) {
    return
  }

  auth.logout()
}

export default {
  call,
  handleSuccess,
  handleError,
  promise
}
