<template>
  <v-navigation-drawer
    light
    permanent
    class="sidebar"
    style="background-color: #333 !important"
    :class="{ showing: showing }"
  >
    <merchant-sidebar
      v-if="isMerchant"
      @choose-merchants="switchToChooseMerchant"
      @open-location="openMerchantLocation"
      @add-location="openAddLocation"
      @open-product="openProduct"
      :locations="locations"
    />

    <choose-merchant-sidebar
      v-else-if="isChooseMerchant"
      @switch-all-merchants="switchToAllMerchants"
      @switch-merchant="switchToMerchant"
      @add-merchant="openAddMerchant"
      @go-back="goBackToMerchant"
    />

    <all-merchants-sidebar
      v-else
      @switch-merchant="switchToMerchantLocation"
      @choose-merchants="switchToChooseMerchant"
      :locations="locations"
    />
    <!-- <div class="page-top">
      <v-row
        class="d-flex justify-space-between mb-16"
      >
        <v-btn
          text
          class="button together pa-2 text-capitalize"
        >
          <img
            src="@/assets/landing_logo@2x.png"
            class="logo" />
          <span>
            {{ name | truncate(20) }}
          </span>
        </v-btn>
        <v-btn
          icon
          class="button"
          color="white"
        >
          <v-icon>
            mdi-chevron-down
          </v-icon>
        </v-btn>

      </v-row>

      <v-row
        class="d-flex justify-space-between pa-1 mr-1">

        <v-text-field
          dark
          class="searchbox"
          placeholder="Search for merchant"
          >

          <template v-slot:prepend-inner>
            <v-icon class="logo">mdi-magnify</v-icon>
          </template>
        </v-text-field>

      </v-row>
    </div>

    <v-list
      flat
      class="list">

      <v-list-item class="item">
        <v-list-item-content>
          <v-list-item-title class="text">
            <img class="logo" src="@/assets/landing_logo@2x.png" />
            {{ $t('link.adding_location') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

  </v-list>

    <div>
      <all-merchants-sidebar
        @switch-merchant="switchToMerchant"
        v-if="isAllMerchant"
      />
      <choose-merchant-sidebar
        @switch-all-merchants="switchToAllMerchants"
        @switch-merchant="switchToMerchant"
        @add-merchant="openAddMerchant"
        v-else-if="isChooseMerchant"
      />
      <merchant-sidebar
        @open-location="openMerchantLocation"
        v-else
      />
    </div> -->
  </v-navigation-drawer>

  <!-- <div
          v-if="isAllMerchant">
          <img src="@/assets/landing_logo@2x.png" class="merchant-img" />
          <span class="merchant-name">
            {{ $t('title.all_merchants') }} <v-icon class="merchant-right-icon">
              mdi-chevron-down
            </v-icon>
          </span>
        </div>

        <div
          v-else-if="isChooseMerchant">
          <img src="@/assets/landing_logo@2x.png" class="merchant-img" />
          <span class="merchant-name">
            {{ $t('title.choose_merchant') }} <v-icon class="merchant-right-icon">
              mdi-chevron-down
            </v-icon>
          </span>
        </div>

        <div
          d-flex justify-space-between mb-6
          v-else>
          <img src="@/assets/landing_logo@2x.png" class="merchant-img" />
          <span class="merchant-name">
              {{ name | truncate(20) }}
              <v-icon class="merchant-right-icon" @click="switchToChooseMerchant">
              mdi-chevron-down
            </v-icon>
          </span>
          <v-btn
            text
            flat
            class="btn-merchant together pa-0 mr-2 text-capitalize"
          >
            <img src="@/assets/landing_logo@2x.png" class="merchant-img" />
            <span class="merchant-name">
              {{ name | truncate(20) }}
            </span>
          </v-btn>
          <v-btn
            icon
            class="btn-merchant"
            color="white"
          >
            <v-icon dark>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </div> -->
</template>

<script>
import MerchantSidebar from '@/components/Sidebar/Merchant/MerchantSidebar'
import AllMerchantsSidebar from '@/components/Sidebar/Merchant/AllMerchantsSidebar'
import ChooseMerchantSidebar from '@/components/Sidebar/Merchant/ChooseMerchantSidebar'
import * as config from '@/config/config'

export default {
  name: 'Sidebar',
  components: {
    MerchantSidebar,
    AllMerchantsSidebar,
    ChooseMerchantSidebar
  },
  data() {
    return {
      name: '',
      currentMerchant: ''
    }
  },
  methods: {
    async getSidebarName() {
      this.name = 'Merchant name'
      const route = this.$route.path
      if (route.includes('locations/add')) {
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
      }
    },
    async switchToMerchantLocation(loc) {
      await this.$router.push({
        name: 'Location',
        params: { merchantId: loc.merchant_id, locationId: loc.id }
      })
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
    },
    async switchToAllMerchants() {
      await this.$router.push({ name: 'AllMerchants' })
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
    },
    async goBackToMerchant() {
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
    },
    async switchToChooseMerchant() {
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_CHOOSE)
    },
    async switchToMerchant(merchantId) {
      await this.openMerchant(merchantId)
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
    },
    async openAddMerchant() {
      await this.$router.push({ name: 'AddMerchant' })
    },
    async openProduct(product, location) {
      await this.$router.push({
        name: 'AddVisitorParking',
        params: { merchantId: location.merchant_id, locationId: location.id }
      })
      await this.$store.dispatch('setCurrentLocation', location)
    },
    async openMerchant(merchantId) {
      // TODO: convert location to merchant
      await this.$router.push({ name: 'Merchant', params: { merchantId } })
    },
    async openCurrentMerchant() {
      const currentMerchant = this.$store.getters.currentMerchant
      await this.$router.push({
        name: 'Merchant',
        params: { merchantId: currentMerchant.id }
      })
    },
    async openAddLocation() {
      const currentMerchant = this.$route.params.merchantId
      await this.$router.push({
        name: 'AddLocation',
        params: { merchantId: currentMerchant }
      })
    },
    async openMerchantLocation(loc) {
      const currentMerchant = this.$route.params.merchantId
      await this.$router.push({
        name: 'Location',
        params: { merchantId: currentMerchant, locationId: loc.id }
      })
    }
  },
  computed: {
    showing() {
      return this.$store.state.sidebarShowing
    },
    isAllMerchant() {
      return this.$store.state.sidebar === config.SIDEBAR.MERCHANT_ALL
    },
    isChooseMerchant() {
      return this.$store.state.sidebar === config.SIDEBAR.MERCHANT_CHOOSE
    },
    isMerchant() {
      return this.$store.state.sidebar === config.SIDEBAR.MERCHANT
    },
    locations() {
      return this.$store.getters.locations
    }
  },
  async created() {
    await this.getSidebarName()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/sidebar_page';

.sidebar {
  height: 100% !important;
  overflow-y: auto;
  scrollbar-width: none;
}

.sidebar::-webkit-scrollbar {
  width: 0; /* Set the width of the scrollbar to 0 to hide it */
  background: transparent; /* Set the background to transparent to hide the scrollbar track */
}
</style>
