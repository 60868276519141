import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from '../auth'
import helpers from '@/helpers'
import i18n from '@/plugins/i18n'
import userHelpers from '@/helpers/user'
import store from '@/store'

const AddMerchant = () =>
  import(/* webpackChunkName: "add-merchant-info" */ '../views/AddMerchant')
const AllMerchants = () =>
  import(
    /* webpackChunkName: "merchant locations" */ '../views/MerchantLocations.vue'
  )
const Merchant = () =>
  import(/* webpackChunkName: "merchant" */ '../views/Dashboard/Merchant.vue')
// const MerchantLocation = () => import(/* webpackChunkName: "merchant-location" */ '../views/MerchantLocations.vue')
const AddLocation = () =>
  import(/* webpackChunkName: "location" */ '../views/AddLocation.vue')
const Location = () =>
  import(/* webpackChunkName: "location" */ '../views/Location.vue')
const SupportUsers = () =>
  import(
    /* webpackChunkName: "support-users" */ '../views/Admin/SupportUsers.vue'
  )
const AddVisitorParking = () =>
  import(
    /* webpackChunkName: "add-visitor-parking" */ '../views/Location/AddVisitorParking.vue'
  )
const WardeningPage = () => import('../views/WardeningPage.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
    meta: {
      loggedIn: false,
      redirectOnAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      loggedIn: false,
      redirectOnAuth: true
    }
  },
  {
    // path: '/locations',
    // name: 'Locations',
    path: '/merchants',
    name: 'AllMerchants',
    component: AllMerchants,
    // component: () => import(/* webpackChunkName: "merchant locations" */ '../views/MerchantLocations.vue'),
    meta: {
      loggedIn: true,
      loadRoles: true,
      roles: ['parkman_admin', 'merchant_admin', 'merchant_user']
      // redirectOnAuth: true
    }
  },
  {
    // path: '/location',
    name: 'Location',
    // component: () => import(/* webpackChunkName: "location" */ '../views/Location.vue'),
    path: '/merchants/:merchantId/locations/:locationId',
    component: Location,
    meta: {
      loggedIn: true,
      loadRoles: true,
      roles: ['parkman_admin', 'merchant_admin', 'merchant_user']
      // redirectOnAuth: true
    }
  },
  {
    path: '/merchants/:merchantId/add',
    name: 'AddLocation',
    component: AddLocation,
    // component: () => import(/* webpackChunkName: "location" */ '../views/AddLocation.vue'),
    meta: {
      loggedIn: true,
      roles: ['parkman_admin', 'merchant_admin', 'merchant_user']
    }
  },
  {
    path: '*',
    meta: {
      unknownRoute: true
    }
  },
  {
    path: '/merchants/add-merchant',
    name: 'AddMerchant',
    component: AddMerchant,
    meta: { loggedIn: true, roles: ['parkman_admin'] }
  },
  /* {
    path: '/merchants',
    name: 'AllMerchants',
    component: AllMerchants,
    meta: { loggedIn: false }
  }, */
  {
    path: '/merchants/support-users',
    name: 'SupportUsers',
    component: SupportUsers,
    meta: { loggedIn: true }
  },
  {
    path: '/merchants/:merchantId',
    name: 'Merchant',
    component: Merchant,
    meta: {
      loggedIn: true,
      roles: ['parkman_admin', 'merchant_admin', 'merchant_user']
    }
  },
  /* {
    path: '/merchants/locationId',
    name: 'MerchantLocation',
    component: MerchantLocation,
    meta: { loggedIn: false }
  } */
  {
    path: '/merchants/:merchantId/locations/:locationId/visitor-parking',
    name: 'AddVisitorParking',
    component: AddVisitorParking,
    meta: {
      loggedIn: true,
      roles: ['parkman_admin', 'merchant_admin', 'merchant_user']
    }
  },
  {
    path: '/wardens/:wardenName/:wardenKey/web',
    name: 'WardeningPage',
    component: WardeningPage,
    meta: {
      loggedIn: false,
      loadRoles: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = router.push

/**
 * Redefine native router push method in order to except NavigationDuplicated error.
 *
 * @param location
 * @param onResolve
 * @param onReject
 * @returns {Promise<Route>|void}
 */
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (err?.name === 'NavigationDuplicated') {
      return err
    }

    return Promise.reject(err)
  })
}

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)
  // detect language by url specification & by last used
  let language = to.query.lang || helpers.fetchStorage('lang')
  if (!language) {
    const country = await helpers.getVisitorCountry()
    language = helpers.getLanguageByCountry(country)
  }
  // if the language is not supported, set the page to default language
  if (i18n.availableLocales.indexOf(language) === -1) {
    language = process.env.VUE_APP_I18N_FALLBACK_LOCALE
  }
  // set and save the language
  i18n.locale = language
  helpers.saveStorage('lang', language)

  /**
   * If user is not logged in and tries to access protected routes
   * we will redirect the user to login page
   */
  if (to.matched.some((record) => record.meta.loggedIn)) {
    if (!auth.loggedIn()) {
      next({
        path: '/'
      })
      return
    }
  }
  /**
   * If user is logged in and tries to access to login page
   * it will redirect to main page
   */
  if (to.matched.some((record) => record.meta.redirectOnAuth)) {
    if (auth.loggedIn()) {
      next({
        path: '/merchants'
      })
      return
    }
  }
  /**
   * If user tries to access route that require the specific role which he doesn't have
   * it will redirect to main page
   */
  if ((to.meta.roles || to.meta.loadRoles) && auth.loggedIn()) {
    if (store.getters.roleOptions.length === 0) {
      await userHelpers.fetchRoleOptions()
      await userHelpers.fetchAccountRoleOptions()
    }
    if (to.meta.roles && to.meta.roles.length > 0) {
      const hasRoleAccess = to.meta.roles.some((role) => auth.hasRole(role))
      if (!hasRoleAccess) {
        next({
          path: '/merchants'
        })
        return
      }
    }
  }
  /**
   * If user tries to access setup flow after done registration
   * redirect to settings page
   */
  if (to.matched.some((record) => record.meta.redirectOnDoneSetup)) {
    // do this, as some browsers can only store string in local storage
    // @todo: redirect user away from setup page after setup complete
    if (helpers.fetchStorage('company')) {
      next({
        path: '/merchants',
        query: to.query
      })
      return
    }
  }
  /**
   * If route not found (404), redirect to homepage
   */
  if (to.matched.some((record) => record.meta.unknownRoute)) {
    next({
      path: '/'
    })
    return
  }

  next()
})

export default router
